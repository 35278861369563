import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Papa from "papaparse";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfAccordionComponent from "@/vue/components/df-accordion/df-accordion.vue";

@Component({
  components: {
    DfAccordion: DfAccordionComponent,
  },
})
export default class DfProductDetailsExtraContentComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  product!: DfProduct;

  get ingredients(): Array<string> {
    return Utils.getPropertyValues(
      this.product,
      Utils.PROPERTY_PRODUCT_INGREDIENTS,
      "TEXT"
    );
  }

  get nutritionalValues(): Array<any> {
    const CSV: any = Utils.getPropertyValue(
      this.product,
      Utils.PROPERTY_PRODUCT_NUTRITIONAL_VALUES,
      "TEXT"
    );
    return CSV ? Papa.parse(CSV).data : [];
  }

  get calculatedNutritionalValues(): Array<any> {
    const CSV: any = Utils.getPropertyValue(
      this.product,
      Utils.PROPERTY_PRODUCT_NUTRITIONAL_VALUES_CALCULATED,
      "TEXT"
    );
    return CSV ? Papa.parse(CSV).data : [];
  }

  get allergens(): Array<any> {
    return Utils.getPropertyValue(
      this.product,
      Utils.PROPERTY_PRODUCT_ALLERGENS_VALUES,
      "TEXT"
    );
  }
}
